<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-4">
      <h4>{{ question.order }}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div v-for="(input, i) in question.options.values" :key="input.order">
      <div class="form-group mb-4 wet-form input position-relative">
        <label class="mb-2 label fw-bold">{{ input.text }}</label>
        <input class="form-control input-box" type="number" :value="result[i] === 0 ? null : result[i]" @input="handleInput($event.target.value, i )">
        <img src="../../assets/icons/team-icon.jpg" width="25px" class="team-icon" alt="">
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button"  @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {CollaboratorsInitialData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "Collaborators",
  components: {},
  props: {},
  data() {
    return {
      question: CollaboratorsInitialData,
      result: [],
    }
  },
  mounted() {},
  computed: {},
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
      } else {
        const length = store.state.currentQuestion.options.values.length;
        const arr = []
        for(let i = 0; i < length; i++) {
          arr.push(0);
        }
        this.result = arr;
      }
    }
  },
  methods: {
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
    handleInput(e, index) {
      this.result[index] = e;
    }
  },

}
</script>

<style scoped lang="scss">

.icon {
  fill: #23AF4D;
}

.input-box {
  padding-left: 2.5rem
}

.team-icon {
  position: absolute ;
  top: 58%;
  left: 2%;
}

.controls-button {
  &:hover {
    .icon   {
      fill: white ;
    }
  }
}

.label {
  width: auto;
  font-size: 1.3rem
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.input {
  width:  50%;
}

@media (max-width: 700px) {
  .input {
    width: 100%
  }
}
</style>
